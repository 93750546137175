<template>
  <div class="Signup">
    <div class="Signup__info">
      <h1 v-if="!theme.loginWelcomeTextBlack">{{$t('signup_in')}}</h1>
      <h1 v-if="theme.loginWelcomeTextBlack">{{theme.loginWelcomeTextBlack}}</h1>
      <h2 v-if="!theme.loginWelcomeTextColor && VUE_APP_TYPE == 'WAT_LAB'" :style="{ color: theme.mainColor }">WAT Lab</h2>
      <h2 v-if="!theme.loginWelcomeTextColor && !VUE_APP_TYPE == 'WAT_LAB'" :style="{ color: theme.mainColor }">Experience Lab</h2>
      <h2 v-if="theme.loginWelcomeTextColor" :style="{ color: theme.mainColor }">{{theme.loginWelcomeTextColor}}</h2>
      <div class="Signup__info__text">
      <p v-html="theme.signupText"></p>
      </div>
    </div>
    <div class="Signup__form">
      <form>
        <div>
          <label>{{$t('name')}}</label>
          <input type="text" v-model="formSignup.name">
        </div>
        <div>
          <label>{{$t('surname')}}</label>
          <input type="text" v-model="formSignup.surname1">
        </div>
        <div>
          <label>{{$t('profile_personalization_email')}}</label>
          <input type="text" v-model="formSignup.email">
        </div>
        <div>
          <label>{{$t('password')}}</label>
          <input type="password" v-model="formSignup.password">
        </div>
        <div class="Signup__form--acceptance" v-if="theme.afterLoginBypass">
          <div>
            <input type="checkbox" name="ch-acceptance" v-model="formSignup.participation">
            <label for="ch-acceptance" v-if="theme.acceptance.length > 0">{{ theme.acceptance }}</label>
            <label for="ch-acceptance" v-if="theme.acceptance.length == 0 || theme.acceptance == null">{{$t('signup_acceptance_default')}}</label>
          </div>
          <div>
            <input type="checkbox" name="ch-conditions" v-model="formSignup.conditions">
            <label for="ch-conditions">  {{$t('signup_conditions1')}}
              <router-link :to="{ name: 'conditions' }" target="_blank">{{$t('signup_conditions2')}}</router-link>
            </label>
          </div>
        </div>
        <Button color="accent" :buttonType="'submit'" :disabled="!formValid" @click="handleSignup($event)">
          {{$t('action_send')}}
          <i class="fas fa-users button-icon"></i>
          <Spinner v-show="loadingAuth" />
        </Button>
      </form>
      <router-link :to="{ name: 'login' }">{{$t('signup_go_login_page')}}</router-link>
    </div>
    <form-errors :showErrors="authenticationError">{{getAuthenticationErrorMessage()}}</form-errors>
    <email-validator :email="formSignup.email">{{$t('error_need_real_email')}}</email-validator>
    <p style="color: lightgray; padding: 20px 20px 20px 60px;" v-html="theme.initialNotice"></p>
  </div>
</template>

<script>
  import {
    USER_AUTH_LOGIN,
    USER_AUTH_SIGNUP,
  } from '@/store/user.module';
  import { mapGetters } from 'vuex';
  import store from '@/store';
  import Avatar from '~/components/Avatar';
  import Button from '~/components/Button';
  import EmailValidator from '@/components/EmailValidator.vue';
  import FormErrors from '@/components/FormErrors.vue';
  import Spinner from '@/components/Spinner.vue';
  import {
    verifyEmail,
    fetchUserProfile,
  }  from '@/utils';


  export default {
    name: 'LoginPage',

    components: {
      Avatar,
      Button,
      EmailValidator,
      FormErrors,
      Spinner
    },
    data() {
      return {
        formSignup: {
          email: '',
          password: '',
          name:'',
          surname1:'',
          participation:false,
          conditions:false,
          error: false,
        }
      };
    },

    computed: {
      ...mapGetters([
        'theme',
        'isAuthenticated',
        'authenticationError',
        'loadingAuth',
        'nextStep'
      ]),

      formValid() {
        if(this.theme.afterLoginBypass && (!this.formSignup.conditions || !this.formSignup.participation)) return false
        return (
          verifyEmail( this.formSignup.email ) && this.formSignup.password !== '' && this.formSignup.name !== '' && this.formSignup.surname1 !== ''
        );
      },
    },

    methods: {
      async handleSignup(e) {
        e.preventDefault();

        await store.dispatch(USER_AUTH_SIGNUP, {
          communityName: this.$route.params.origin,
          signupData: {
            email: this.formSignup.email,
            password: this.formSignup.password,
            name: this.formSignup.name,
            surname1: this.formSignup.surname1,
            registrationToken:this.$route.params.hash,
          },
        });
        if ( this.isAuthenticated ) {
          const origin = this.$route.params.origin;
          await fetchUserProfile( this );
          if(this.nextStep == "afterLogin") this.$router.push( { name: "completeProfile", params: { origin } } );
          else this.$router.push( { name: this.nextStep, params: { origin } } );
        }
      },
      getAuthenticationErrorMessage() {
        if (this.authenticationError) {
          if (this.authenticationError.data.error == 'json validation error') {
            if (this.authenticationError.data.payload == 'User email already exists') {
              return this.$t('error_email_already_exists');
            } else {
              return this.$t('error_new_password_not_valid', {simbols:'(@ $ ! % * ? &)'});
            }
          } else {
            return this.$t('signupt_not_permitted');
          }
        }
      }
    }
  };
</script>
<style lang="scss" scoped>
.Signup {
  width                 : 100%;


  @media only screen and ( max-width: 769px ) {
    width               : 100%;
    padding             : 1rem 0;
  }

  &__info {
    width               : 75%;
    display             : flex;
    flex-direction      : column;
    align-items         : flex-start;
    margin-left         : 60px;

    h1 {
      font-size         : 25px;
      font-family       : inherit;
      font-weight       : 700;
      margin-bottom     : 0;
      color             : #191919;
    }

    h2 {
      margin            : 0;
      margin-top        : -10px;
      padding           : 0 0 10px 0;
      border-bottom     : 1px solid #e9e9e9;
      font-size         : 40px;
      font-family       : inherit;
      font-weight       : 700;
    }

    p {
      color             : #797979;
      font-size         : 13px;
    }

    &__text {
      padding-top       : 10px;
      padding-bottom    : 10px;
    }
  }

  &__form {
    padding             : 20px 0 20px 60px;
    background          : #ededed;
    display             : flex;
    flex-direction      : column;
    align-items         : flex-start;

    form {
      display           : flex;
      align-items       : flex-end;
      flex-wrap         : wrap;
      margin            : 5px 0;
      width             : 75%;
      @media only screen and ( max-width: 1000px ) {
        width               : 100%;
      }
      .Button {
        margin-top      : 10px;
      }

      div {
        display         : flex;
        flex-direction  : column;
        margin-right    : 10px;
        &:nth-child(2){
          break-after: always;
        }
        label {
          font-size     : 12px;
          color         : #494949;
          font-weight   : bold;
        }

        input {
          padding       : 10px;
          margin-top    : 5px;
          border        : none;
          outline       : none;
          box-shadow    : 0px 0px 20px 0px rgba( 0, 0, 0, 0.1 );
          transition    : box-shadow 300ms;

          &:focus {
            box-shadow  : 0px 0px 20px 0px rgba( 0, 0, 0, 0.3 );
          }
        }
      }
    }
    a {
      color             : var( --primary-color );
      font-size         : 12px;

      &:hover {
        text-decoration : underline;
      }
    }
    &--acceptance{
      margin: 10px 0;
      margin-left: 8px;
      div{
        display: flex;
        flex-direction: row!important;
        align-items: baseline;
        margin: 10px 0;
        input{
          position:relative;
          margin-right: 5px;
          outline: none;
        }
        input::before{
          height:15px;
          width: 15px;
          border:1px solid var(--primary-color);
          color: transparent;
          font-family: "Font Awesome 5 Free";
          font-weight: 900;
          content:'\f00c';
          position: absolute;
          background: white;
          cursor:pointer;
        }
        input:checked::before{
          color: var(--primary-color);
        }
      }
    }
  }

  .button-icon{
    margin: auto auto auto 5px;
    font-size: 18px;
  }
}

@media only screen and ( max-width: 769px ) {
  .Signup {
    &__info {
      margin            : 1rem auto;
    }
    &__form {
      padding           : 20px;

      form {
        flex-direction  : column;
        width           : 100%;

        .Button--Accent {
          margin-top    : 10px;
          width         : 100%;
          height        : 34px;
          font-size     : 16px;
        }

        div {
          width         : 100%;
          margin-right  : 0;

          input {
            margin      : 5px 0;
          }
        }
      }
    }
  }
}
</style>
